$primary: #23366c;
$primary_hover: #002aa3;
$primary_focus: #5f99ff;
$primary_blue: #0041d9;
$light_blue: #8b95b1;
$light_blue1: #e6edf9;
$white: #ffffff;
$black: #000000;
$color_f4f5: #f4f5f8;
$color_e7e7: #e7e7e7;
$body_color: #f7f8fc;
$placeholder_color: #c1c7d7;
$light_warning: #fff9e1;
$green: #4d9335;
$light_green: #8cbf59;
$light_success: #26d5ce;
$light_success_hover: #1eaaa4;
$select_hover: #e8eaf0;
$dark_layout: #20334e;
$light_layout: #f4f5f8;
$pink: #fb306e;
$grey: #6c757d;
$toast_background: #e74c3c;
$red_hover: #ff5d81;
$pink_hover: #c1043e;
$old_mint: #77c99c;
$disable_btn: #c1c7d7;
$error-color: #ff7959;
$gloabal_width: 1440px;
$big_gloabal_width: 1920px;

$mediaSizes: (
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1500px,
  'xxxl': 1950px,
  'maxxl': 3000px
);

// font style
@import './fonts';

body {
  font-family: 'Mulish', sans-serif !important;
  background-color: $body_color !important;
}
.common-container {
  margin: 0 auto;
  padding: 0 !important;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    max-width: $gloabal_width !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    max-width: $big_gloabal_width;
  }
}

.uppercase {
  text-transform: uppercase;
}

// mixins

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@mixin all_center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
@mixin linebreak1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
@mixin linebreak2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
@mixin scroll_horizontal {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $body_color;
  }
  &::-webkit-scrollbar {
    height: 0.375rem;
    background-color: $body_color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($placeholder_color, 1);
  }
}

@mixin scroll_vertical {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $body_color;
  }

  &::-webkit-scrollbar {
    width: 0.375rem;
    background-color: $body_color;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($placeholder_color, 1);
  }
}

@mixin scroll_card {
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 10px);
  padding-right: 10px;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $body_color;
  }

  &::-webkit-scrollbar {
    width: 0.375rem;
    background-color: $body_color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba($placeholder_color, 1);
  }
}

body {
  @include scroll_vertical();
}

// HEADER SECTION
.header-section {
  text-align: center;
  margin-bottom: 40px;

  .holder {
    max-width: 672px;
    margin: 0 auto;
    h2 {
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 16px;
      color: $primary;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        font-size: 25px;
        line-height: 35px;
      }
      @media (max-width: map-get($mediaSizes, 'sm')) {
        font-size: 22px;
        line-height: 32px;
      }
    }
    p {
      font-size: 21px;
      line-height: 30px;
      font-weight: 400;
      margin-bottom: 0px;
      color: $primary;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}

// VALIDATED BADGE SECTION
.validated-badge-section {
  .holder {
    .row {
      margin: 0 -27.5px;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        margin: 0 -15px;
      }
      .inner-col {
        padding: 0 27.5px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          padding: 0 15px;
        }
        @media (max-width: map-get($mediaSizes, 'md')) {
          padding: 0 0px;
        }
      }
    }
    .form-group {
      margin-bottom: 23px;
      &.mb-0 {
        margin-bottom: 0 !important;
      }
    }
    .inner-title {
      font-size: 21px;
      line-height: 30px;
      font-weight: 400;
      color: $primary;
      margin-bottom: 38px;
      @media (max-width: map-get($mediaSizes, 'xl')) {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 25px;
      }
      @media (max-width: map-get($mediaSizes, 'lg')) {
        font-size: 16px;
      }
    }

    .button-tab-bottom {
      text-align: right;
      text-transform: uppercase;
      @media (max-width: map-get($mediaSizes, 'md')) {
        text-align: left;
      }
    }
  }
}

//common-class
.hidden {
  display: none;
}
.span-10 {
  font-size: 10px;
  line-height: 12.55px;
  color: $light_blue;
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 10px !important;
    line-height: 12.55px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 14px;
    line-height: 20px;
  }
}
@mixin span_14 {
  color: $primary;
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 16px;
    line-height: 25px;
  }
}
.span-14 {
  @include span_14();
}
@mixin span_16 {
  font-weight: 400;
  display: block;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 18px;
    line-height: 28px;
  }
}
.span-16 {
  @include span_16();
}

.form-label {
  color: $primary;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 16px;
    line-height: 21px;
  }
}
.pointer {
  cursor: pointer;
}
.fw300 {
  font-weight: 300 !important;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.fw800 {
  font-weight: 800 !important;
}
.fw900 {
  font-weight: 900 !important;
}

.line-break-1 {
  @include linebreak1();
}
.line-break-2 {
  @include linebreak2();
}
.form-control,
input,
input.form-control {
  min-height: 48px;
  border-radius: 8px !important;
  border: 1px solid $primary !important;
  color: $primary !important;
  font-size: 14px;
  line-height: 21px;
  padding: 14px 12px;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &.flatpickr-input {
    &:disabled,
    &[readonly] {
      background: $white !important;
    }
  }
}

.error-msg {
  font-weight: 400;
  color: $error-color;
  margin: 0px 12px;
  display: block;
  margin-top: 2px;
  @media (max-width: map-get($mediaSizes, 'xxl')) {
    font-size: 10px !important;
    line-height: 13px;
  }
  @media (max-width: map-get($mediaSizes, 'maxxl')) {
    font-size: 14px;
    line-height: 14px;
  }
}
textarea.form-control {
  padding: 14px 12px;
}
input.form-control,
textarea.form-control {
  font-size: 14px;
  line-height: 17.37px;
  color: $primary;
  font-weight: 400;
  &:focus {
    color: $primary_hover;
  }
  &::placeholder {
    color: $primary;
  }
  &::-webkit-input-placeholder {
    color: $primary;
  }
  &::-moz-placeholder {
    color: $primary;
  }
  &:-ms-input-placeholder {
    color: $primary;
  }
  &:-moz-placeholder {
    color: $primary;
  }
}
//
.form-group {
  &.error-gives {
    .floating-label {
      color: $error-color !important;
    }
    input {
      border-color: $error-color !important;
    }
    textarea::placeholder,
    input::placeholder {
      color: $error-color !important;
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: $error-color !important;
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
      color: $error-color !important;
    }
    input:-ms-input-placeholder,
    textarea::-moz-placeholder {
      color: $error-color !important;
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: $error-color !important;
    }
  }
}
.floating-label-group {
  position: relative;
  &.dark {
    input:focus ~ .floating-label,
    input:not(:placeholder-shown) ~ .floating-label,
    textarea:focus ~ .floating-label,
    textarea:not(:placeholder-shown) ~ .floating-label {
      color: $white;
      background: transparent !important;
      top: -19px;
    }
  }
  .floating-label {
    font-size: 14px;
    line-height: 17.37px;
    color: $primary !important;
    margin: 0px 11px 0px 5px;
    position: absolute;
    pointer-events: none;
    top: 15px;
    left: 1px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    text-transform: capitalize;
  }

  input:focus ~ .floating-label,
  input:not(:placeholder-shown) ~ .floating-label,
  textarea:focus ~ .floating-label,
  textarea:not(:placeholder-shown) ~ .floating-label {
    top: -10px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
    padding: 0px 2px 0px 1px;
    color: $primary;
    background: $white;
    height: fit-content;
  }
  textarea:focus::placeholder,
  input:focus::placeholder {
    opacity: 0;
  }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    opacity: 0;
  }
  input:focus:-ms-input-placeholder,
  textarea:focus::-moz-placeholder {
    opacity: 0;
  }
  input:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder {
    opacity: 0;
  }
}

.btn {
  transition: 0.5s ease-in-out !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.btn-cu-xl {
  min-width: 200px;
}
.btn-cu-lg {
  min-width: 147px;
}
.btn-cu-md {
  min-width: 110px;
}
.btn-cu-sm {
  min-width: 80px;
}
.react-icon-48 {
  border-radius: 50%;
}
.react-12-cut {
  border-radius: 12px 12px 0px 12px;
}
.react-12 {
  border-radius: 12px;
}
.react-16 {
  border-radius: 16px;
}
.react-20 {
  border-radius: 20px;
}
.btn {
  &.disabled {
    background: $disable_btn !important;
    background-color: $disable_btn !important;
    color: $white !important;
  }
}
.btn-icon {
  width: 48px;
  height: 48px;
  padding: 0 !important;
  @include all_center();
  @media (max-width: map-get($mediaSizes, 'md')) {
    width: 40px;
    height: 40px;
    min-height: 40px !important;
    padding: 5px !important;
  }
  &.btn-primary {
    background: $primary_blue;
    border-color: $primary_blue;
    color: $white;
    border-radius: 50% !important;
  }
}
.btn-white {
  background: $white !important;
}
.btn-primary {
  background: $primary_blue !important;
  border-color: $primary_blue !important;
  color: $white !important;
  min-height: 48px;
  border-radius: 12px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    background: $primary_hover !important;
    border-color: $primary_hover !important;
  }
  &:focus {
    background: $primary_focus !important;
    border-color: $primary_focus !important;
  }
}
.btn-success {
  background: $light_green !important;
  border-color: $light_green !important;
  color: $white !important;
  min-height: 43px;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 12px 12px 0px 12px !important;
  padding: 7px 5px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    background: $green !important;
    border-color: $green !important;
  }
}
.btn-light-success {
  // background: $light_success !important;
  // border-color: $light_success !important;
  background: $primary_blue !important;
  border-color: $primary_blue !important;
  color: $white !important;
  height: 48px;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 16px !important;
  padding: 9px 33px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    // background: $light_success_hover !important;
    // border-color: $light_success_hover !important;
    background: $primary_hover !important;
    border-color: $primary_hover !important;
  }
}
.btn-pink {
  // background: $pink !important;
  // border-color: $pink !important;
  background: $primary_blue !important;
  border-color: $primary_blue !important;
  color: $white !important;
  height: 48px;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
  padding: 6px 24px !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    // background: $pink_hover !important;
    // border-color: $pink_hover !important;
    background: $primary_hover !important;
    border-color: $primary_hover !important;
  }
}
.title {
  h3 {
    color: $primary;
    font-weight: 400;
    margin-bottom: 0;
    &.mb23 {
      margin-bottom: 23px;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 18px !important;
      line-height: 23px !important;
    }
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
#root {
  .with-box .mid-section {
    background-color: $body_color !important;
    position: relative;
    &.with-full-layout {
      position: absolute;
      top: 0;
      left: 0;
      // z-index: 999;
      min-height: 100vh;
      width: 100%;
      padding: 0;
      background-color: $light_layout !important;
      overflow-x: auto;
      .full-bg {
        min-height: 100%;
        width: 100%;
        background-size: cover;
        background-position: right top;
        padding: 36px 52px 120px 52px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          padding: 40px 31px 90px 31px;
        }
        @media (max-width: map-get($mediaSizes, 'sm')) {
          padding: 24px 21px 70px;
        }
      }
    }
  }
}

// loader
.loader {
  height: 100vh;
  width: 100%;
  z-index: 99999999;
  position: fixed;
  background: rgba($light_layout, 0.3);
  .loader-data {
    height: 100vh;
    width: 100%;
    @include all_center();
    .spinner {
      width: 100px;
      height: 100px;
      animation: spin 1.5s infinite;
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: 80px;
        height: 80px;
      }
    }
  }
}

/* =================================== Language Dropdown ============================== */

.language-dropdown {
  position: fixed;
  bottom: 0;
  right: 0;

  .dropdown-toggle {
    background: transparent;
    color: black;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: ease-in-out 0.3s;
  }

  .dropdown-toggle:hover {
    opacity: 0.8;
  }

  .dropdown-arrow {
    margin-left: 5px;
  }

  .dropdown-menu {
    position: absolute;
    bottom: 100%;
    right: 10%;
    background-color: white;
    border: none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0;
    width: 100%;
    min-width: auto;
    border-radius: 10px;
    display: none;

    &.open {
      display: block;
    }

    li {
      list-style: none;

      button {
        width: 100%;
        padding: 10px 20px;
        text-align: left;
        background: none;
        border: none;
        color: #23366c;
        cursor: pointer;
        font-size: inherit;
        transition: background-color 0.2s ease, color 0.2s ease, border-radius 0.2s ease;

        &:hover,
        &.active {
          background-color: #0041d9;
          color: #fff;
        }
      }
    }
    // Ensure li elements respect border-radius
    li:first-child button {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    li:last-child button {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

/* =================================== Spinner ============================== */
.spinner-block {
  height: 100vh;
  width: 100%;
  z-index: 99999999;
  position: fixed;
  background: rgba($light_layout, 1);
  .loader {
    height: 100vh;
    width: 100%;
    @include all_center();
    .spinner {
      width: 100px;
      height: 100px;
      animation: spin 1.5s infinite;
      @media (max-width: map-get($mediaSizes, 'md')) {
        width: 80px;
        height: 80px;
      }
    }
  }
}
// select react
.select__control {
  height: 48px;
  border-radius: 8px !important;
  border-color: $primary !important;
  background: url('../../assets/image/icon-select-arrow.svg') no-repeat $white;
  background-position: right 21px center;
  &.select__control--is-focused {
    box-shadow: none !important;
  }
  .select__value-container {
    padding: 0 10px;
  }
  .select__indicator-separator {
    display: none;
  }
  .select__indicator.select__dropdown-indicator {
    opacity: 0;
  }
  .select__single-value {
    font-size: 14px;
    color: $primary;
  }
}
.select__menu {
  border-radius: 8px;
  .select__menu-list {
    max-height: 196px;
    overflow-y: auto;
    padding: 0 !important;
    @include scroll_vertical();
  }
  .select__option {
    padding: 13.2px 16px;
    font-size: 14px;
    line-height: 21px;
    min-height: 40px;
    color: $primary;
    &:hover,
    &:active,
    &.select__option--is-selected {
      background-color: $select_hover;
    }
  }
}

/* notfound-section */
section.notfound-section {
  @include all_center();
  min-height: 100vh;
  h1 {
    color: $primary;
    font-weight: 700;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 6rem !important;
      line-height: 8rem !important;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 8rem;
      line-height: 9rem;
    }
    @media (max-width: map-get($mediaSizes, 'maxxl')) {
      font-size: 10rem;
      line-height: 11rem;
    }
  }
  .page-title {
    margin-bottom: 20px;
    font-weight: 400;
    @media (max-width: map-get($mediaSizes, 'md')) {
      font-size: 18px !important;
      line-height: 25px !important;
    }
    @media (max-width: map-get($mediaSizes, 'xxl')) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  .page-sub-title {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  p {
    margin-bottom: 2rem;
    text-transform: unset !important;
  }
}

//dropzone-inner start
.dropzone-inner {
  padding: 60px 30px;
  text-align: center;
  border-radius: 8px;
  border: 1px dashed $primary;
  background: $white;
  @media (max-width: map-get($mediaSizes, 'md')) {
    padding: 40px 20px;
  }
  cursor: pointer;
  img {
    margin-bottom: 10px;
  }
  p {
    max-width: 150px;
    margin-bottom: 0;
    margin: 0 auto;
    font-size: 14px;
    line-height: 18px;
    color: $primary;
    font-weight: 400;
  }
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
}

.thumbInner {
  display: flex;
  align-items: center;
  min-width: 0px;
  overflow: hidden;
}

.img {
  display: block;
  max-width: 100%;
  height: 100%;
}
.thumb {
  position: relative;
  display: inline-flex;
  border-radius: 4;
  border: 1px dashed $primary;
  background: $white;
  margin-bottom: 20px;
  margin-right: 20px;
  height: 160px;
  max-width: 320px;
  padding: 10px;
  box-sizing: border-box;
  .btn-delete {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    padding: 0 !important;
    padding-right: 1px !important;
    right: -12.5px;
    top: -12.5px;
    @include all_center();
    img {
      width: 12px;
      transform: rotate(45deg);
    }
  }
}
.pos-rel {
  position: relative;
  z-index: 9999;
}
//dropzone-inner end

// with-full-layout
.with-full-layout {
  // header
  header {
    .container-fluid {
      padding: 0;
      @media (max-width: map-get($mediaSizes, 'xxl')) {
        max-width: $gloabal_width !important;
      }
      @media (max-width: map-get($mediaSizes, 'maxxl')) {
        max-width: $big_gloabal_width;
      }
    }
    .navbar {
      padding: 0;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        .collapse:not(.show) {
          display: block;
        }
      }
      .navbar-collapse {
        @media (max-width: map-get($mediaSizes, 'lg')) {
          position: fixed;
          top: 0;
          right: -105%;
          max-width: 375px;
          width: 100%;
          background: $light_layout;
          height: 100vh;
          z-index: 999;
          padding: 112px 28px 32px 16px;
          overflow-x: hidden;
          overflow-y: auto;
          transition: 0.3s ease-in-out;
          &.show {
            right: 0;
          }
          @include scroll_vertical();
        }
      }
    }
    .navbar-brand {
      img {
        @media (max-width: map-get($mediaSizes, 'sm')) {
          max-width: 283px;
        }
        @media all and (max-width: 380px) {
          max-width: 211px;
        }
      }
    }
    ul.navbar-nav {
      display: flex;
      align-items: center;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        display: block;
        text-align: right;
      }
      li {
        margin-right: 44px;
        @media (max-width: map-get($mediaSizes, 'xl')) {
          margin-right: 25px;
        }
        @media (max-width: map-get($mediaSizes, 'lg')) {
          margin-right: 0px;
          margin-bottom: 28px;
          // &:nth-last-child(2),
          // &:nth-last-child(3) {
          //   margin-bottom: 16px;
          // }
        }
        a.nav-link {
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: $primary;
          &:hover {
            color: $light_success;
          }
          @media (max-width: map-get($mediaSizes, 'lg')) {
            font-size: 26px;
            line-height: 1.4em;
            padding: 0;
            &.text {
              color: $red_hover;
              font-size: 14px;
              line-height: 18px;
              text-decoration: none;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
        .btn-light-success {
          min-width: 146px;
        }
      }
    }
    .navbar-toggler {
      border: 0;
      padding: 0;
      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
        background: transparent;
        background-color: transparent;
      }
    }
  }

  // validator-section-1
  .validator-section-1 {
    text-align: center;
    padding: 29px 0px 45px;
    @media (max-width: map-get($mediaSizes, 'lg')) {
      padding: 35px 0px 35px;
    }
    @media (max-width: map-get($mediaSizes, 'sm')) {
      padding: 24px 0px 24px;
    }
    .validator-section-1-data {
      max-width: 672px;
      margin: 0 auto;
      h2 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 600;
        margin-bottom: 16px;
        color: $primary;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          font-size: 25px;
          line-height: 35px;
        }
        @media (max-width: map-get($mediaSizes, 'sm')) {
          font-size: 22px;
          line-height: 32px;
        }
      }
      p {
        font-size: 21px;
        line-height: 30px;
        font-weight: 400;
        margin-bottom: 0px;
        color: $primary;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }

  // validator-section-2
  .validator-section-2 {
    margin: 0 auto;
    padding: 0px 0px 0px;

    .validator-section-2-data {
      .row {
        margin: 0 -27.5px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          margin: 0 -15px;
        }
        .inner-col {
          padding: 0 27.5px;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            padding: 0 15px;
          }
          @media (max-width: map-get($mediaSizes, 'md')) {
            padding: 0 0px;
          }
        }
      }
      .form-group {
        margin-bottom: 23px;
        &.mb-0 {
          margin-bottom: 0 !important;
        }
      }
      .inner-title {
        font-size: 21px;
        line-height: 30px;
        font-weight: 400;
        color: $primary;
        margin-bottom: 38px;
        @media (max-width: map-get($mediaSizes, 'xl')) {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 25px;
        }
        @media (max-width: map-get($mediaSizes, 'lg')) {
          font-size: 16px;
        }
      }

      .button-tab-bottom {
        text-align: right;
        text-transform: uppercase;
        @media (max-width: map-get($mediaSizes, 'md')) {
          text-align: left;
        }
      }
    }
  }
  .validator-chat {
    height: 48px;
    width: 48px;
    position: fixed;
    right: 16px;
    bottom: 40px;
    .btn-white {
      background-color: $white;
      border-radius: 12px 12px 0px 12px;
    }
  }
}

// badge-validator-section-1
.badge-validator-section-1 {
  max-width: 1000px;
  padding: 25px 20px;
  position: relative;
  border: 1px solid #23366c;
  border-radius: 10px;
  margin: 0 auto;
  h3.modal-title {
    margin-bottom: 7px;
    color: $primary;
  }
  p.modal-text {
    margin-bottom: 37px;
    color: $primary;
  }
  .react-12-cut {
    margin: 0 auto;
  }
  .modal-big-btn {
    margin: 0 auto;
    border-radius: 12px 12px 0px 12px !important;
    @include all_center();
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600 !important;
    .btn {
      border-radius: 20px;
    }
  }
  .noties {
    max-width: 1000px;
    margin: 0 auto;
    color: #23366c;
    background: #ece8e8;
    border-radius: 20px;
    padding: 10px;
    margin-top: 25px;
  }
  .modal-buttons {
    margin-bottom: 50px;
    @media (max-width: map-get($mediaSizes, 'md')) {
      margin-bottom: 30px;
    }
    ul {
      margin: 0 auto;
      padding-left: 0;
      list-style: none;
      width: fit-content;
      li {
        display: inline-block;
        margin-right: 15px;
        @media (max-width: map-get($mediaSizes, 'md')) {
          display: block;
          margin-right: 0;
          margin-bottom: 15px;
          text-align: center;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        .btn {
          min-height: 32px;
          padding: 5px 26px !important;
          border-radius: 100px;
          font-size: 14px;
          font-weight: 700;
          line-height: 21px;
          color: $white;
          text-transform: uppercase;
          cursor: text;
          &.btn-mint {
            background-color: $old_mint;
            &.novalid {
              background-color: $pink;
            }
            &.novalid-grey {
              background-color: $grey;
            }
          }
        }
      }
    }
  }
  .grid-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    column-gap: 21px;
    row-gap: 16px;
    margin-bottom: 9px;
    @media (max-width: map-get($mediaSizes, 'lg')) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media all and (max-width: 815px) {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 0px;
      display: block !important;
    }
    &.detailed-section {
      // max-width: 1000px;
      margin: 0 auto;

      .card-height {
        height: 40px !important;
        @include scroll_card;
      }
      .left-container {
        width: 100%;

        .card {
          &.badge-image {
            height: auto;
            min-height: 100px;
            .image {
              display: flex;
              margin-top: 10px;
              justify-content: center;
              align-items: center;
              padding: 15px;
              height: 170px;

              @media (max-width: map-get($mediaSizes, 'lg')) {
                max-height: 184px !important;
                height: 184px;
              }
              @media (max-width: map-get($mediaSizes, 'xxl')) {
                max-height: 170px;
              }
              // @media (max-width: map-get($mediaSizes, 'maxxl')) {
              //   max-height: 189px;
              // }

              img {
                max-height: 180px;

                @media (max-width: map-get($mediaSizes, 'xxl')) {
                  max-height: 180px !important;
                }
              }
            }
          }
          .card-body {
            label {
              color: $primary;
              font-size: 10px;
              font-weight: bold;
              text-transform: uppercase;
            }
            p {
              color: $primary;
              font-size: 14px;
              margin-top: 10px;

              &.two-line-scroll {
                height: 40px !important;
              }

              @media screen and (max-width: map-get($mediaSizes, 'lg')) {
                &.scroll {
                  @include scroll_card;

                  &.two-line-scroll {
                    max-height: 40px !important;
                  }
                }
              }
            }
            span {
              color: $primary;
              font-size: 14px;
            }

            @media (max-width: map-get($mediaSizes, 'md')) {
              label {
                font-size: 10px !important;
              }
            }

            @media (max-width: map-get($mediaSizes, 'lg')) {
              label {
                font-size: 8px;
              }
            }
          }
        }
      }
      .center-container {
        width: 100%;

        .card {
          .card-body {
            label {
              color: $primary;
              font-size: 10px;
              font-weight: bold;
              text-transform: uppercase;
            }
            p {
              color: $primary;
              font-size: 14px;
              margin-top: 10px;
            }

            @media (max-width: map-get($mediaSizes, 'md')) {
              label {
                font-size: 10px !important;
              }
            }

            @media (max-width: map-get($mediaSizes, 'lg')) {
              label {
                font-size: 8px;
              }
            }
          }
          &.issuer {
            // .scroll {
            //   @include scroll_card;

            //   @media (min-width: map-get($mediaSizes, 'md')) {
            //     height: 21px;
            //   }
            // }
          }
          &.badge-desc {
            .scroll {
              height: 227px;
              @include scroll_card;

              @media (max-width: map-get($mediaSizes, 'lg')) {
                max-height: 358px !important;
              }
              @media (max-width: map-get($mediaSizes, 'maxxl')) {
                max-height: 214px;
              }
            }
          }
          &.badge-desc-full {
            .scroll {
              height: 318px;
              @include scroll_card;

              // @media (max-width: map-get($mediaSizes, 'lg')) {
              //   max-height: 446px !important;
              // }

              // @media (max-width: map-get($mediaSizes, 'xxl')) {
              //   max-height: 318px;
              // }
            }
          }
        }
      }
      .right-container {
        width: 100%;

        .card {
          .card-body {
            label {
              color: $primary;
              font-size: 10px;
              font-weight: bold;
              text-transform: uppercase;
            }
            p {
              color: $primary;
              font-size: 14px;
              margin-top: 10px;
            }
            a {
              display: inline-block;
              color: $primary;
              font-size: 14px;
              margin-top: 10px;
            }

            @media (max-width: map-get($mediaSizes, 'md')) {
              label {
                font-size: 10px !important;
              }

              a {
                font-size: 14px;
              }
            }

            @media (max-width: map-get($mediaSizes, 'lg')) {
              label {
                font-size: 8px;
              }

              a {
                font-size: 12px;
              }
            }
          }
          &.criteria-desc {
            .scroll {
              height: 200px;
              @include scroll_card;

              @media (max-width: map-get($mediaSizes, 'lg')) {
                max-height: 200px !important;
              }

              @media (min-width: map-get($mediaSizes, 'xxl')) {
                max-height: 188px !important;
              }

              @media (max-width: map-get($mediaSizes, 'maxxl')) {
                max-height: 188px;
              }
            }
          }
          &.badge-offer-link {
            .scroll {
              height: 40px;
              @include scroll_card;
            }
          }
        }
      }
    }
    .card {
      max-width: 440px;
      border-radius: 10px;
      border: 0;
      margin-bottom: 21px;
      background-color: $white;
      border: 1px solid #003366;
      @media all and (max-width: 815px) {
        max-width: 100%;
      }
      &.min-317 {
        @media (max-width: map-get($mediaSizes, 'xxl')) {
          min-height: 354px !important;
        }
        @media (max-width: map-get($mediaSizes, 'maxxl')) {
          min-height: 470px;
        }
      }
      &.novalid {
        border-color: $pink;
        .card-body {
          padding: 37px 25px 33px 25px !important;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            padding: 1rem;
          }
          @media all and (max-width: 815px) {
            padding: 37px 25px 33px 25px !important;
          }
          p {
            color: $pink;
          }
        }
      }
      &.novalid-grey {
        border-color: $grey;
        .card-body {
          padding: 37px 25px 33px 25px !important;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            padding: 1rem;
          }
          @media all and (max-width: 815px) {
            padding: 37px 25px 33px 25px !important;
          }
          p {
            color: $grey;
          }
        }
      }
      &.light-card {
        background-color: $light_blue1;
        .card-body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .one {
            padding-right: 16px;
          }
          span {
            color: $light_blue !important;
          }
          p {
            color: $primary !important;
          }
        }
      }
      .card-body {
        padding: 23px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          padding: 1rem;
        }
        @media all and (max-width: 815px) {
          padding: 37px 25px 33px 25px !important;
        }
        .span-10 {
          margin-bottom: 10px;
          text-transform: uppercase;
          font-size: 10px;
          color: $primary;
          @media all and (max-width: 890px) and (min-width: 815px) {
            font-size: 8px !important;
          }
        }
        p,
        a.text {
          // @include span_16();
          font-size: 14px !important;
          color: $primary;
          margin-bottom: 0px;
        }
        a.text {
          // @include span_14();
          font-size: 14px !important;
          // font-weight: 600 !important;
          color: $primary !important;
          margin-bottom: 0px;
          transition: 0.3s ease-in-out;
          word-break: break-all;
          &:hover {
            color: $red_hover !important;
          }
        }
      }
    }
  }
}
// modal-big
.modal-dialog-scrollable {
  .modal-body {
    @include scroll_vertical();
  }
}
.modal-backdrop.show {
  &.op-8 {
    background: $dark_layout;
    opacity: 0.8 !important;
  }
  &.op-35 {
    background: $dark_layout;
    opacity: 0.35 !important;
  }
}
.modal-big {
  @media (min-width: map-get($mediaSizes, 'xs')) {
    &.modal-dialog {
      width: 1163px;
      max-width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.with-small {
    @media (min-width: map-get($mediaSizes, 'xs')) {
      &.modal-dialog {
        width: 769px;
      }
    }
    .modal-body {
      .modal-buttons {
        margin-bottom: 72px;
        @media (max-width: map-get($mediaSizes, 'lg')) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .modal-content {
    box-shadow: 0px 3px 16px 0px rgba($black, 0.16);
    border: 0;
    border-radius: 12px;
  }
  .modal-body {
    position: relative;
    background: $dark_layout;
    border: 1px solid $white;
    padding: 33px 47px 36px 47px;
    border-radius: 12px;
    @media (max-width: map-get($mediaSizes, 'lg')) {
      padding: 33px 15px 36px 15px;
    }
    @media all and (max-width: 815px) {
      padding: 33px 47px 36px 47px;
    }
    @media (max-width: map-get($mediaSizes, 'sm')) {
      padding: 40px 20px 36px 20px;
    }
    .close-modal {
      position: absolute;
      top: 29px;
      right: 29px;
      height: 16px;
      width: 16px;
      svg {
        path {
          transition: 0.3s ease-in-out;
        }
        &:hover {
          path {
            fill: $placeholder_color;
          }
        }
      }
      @media (max-width: map-get($mediaSizes, 'sm')) {
        top: 10px;
        right: 20px;
      }
    }
    h3.modal-title {
      margin-bottom: 7px;
      color: $white;
    }
    p.modal-text {
      margin-bottom: 37px;
      color: $white;
    }
    .react-12-cut {
      margin: 0 auto;
    }
    .modal-big-btn {
      margin: 0 auto;
      border-radius: 12px 12px 0px 12px !important;
      @include all_center();
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600 !important;
      .btn {
        border-radius: 20px;
      }
    }
    .modal-buttons {
      margin-bottom: 50px;
      @media (max-width: map-get($mediaSizes, 'md')) {
        margin-bottom: 30px;
      }
      ul {
        margin: 0 auto;
        padding-left: 0;
        list-style: none;
        width: fit-content;
        li {
          display: inline-block;
          margin-right: 15px;
          @media (max-width: map-get($mediaSizes, 'md')) {
            display: block;
            margin-right: 0;
            margin-bottom: 15px;
            text-align: center;
            &:last-child {
              margin-bottom: 0;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          .btn {
            min-height: 32px;
            padding: 5px 26px !important;
            border-radius: 100px;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            color: $white;
            text-transform: uppercase;
            cursor: text;
            &.btn-mint {
              background-color: $old_mint;
              &.novalid {
                background-color: $pink;
              }
              &.novalid-grey {
                background-color: $grey;
              }
            }
          }
        }
      }
    }
    .grid-body {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 21px;
      row-gap: 16px;
      margin-bottom: 9px;
      @media (max-width: map-get($mediaSizes, 'lg')) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media all and (max-width: 815px) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 0px;
        display: block !important;
      }
      .card {
        max-width: 342px;
        border-radius: 8px;
        border: 0;
        margin-bottom: 21px;
        background-color: $dark_layout;
        border: 1px solid $white;
        @media all and (max-width: 815px) {
          max-width: 100%;
        }
        &.min-317 {
          @media (max-width: map-get($mediaSizes, 'xxl')) {
            min-height: 354px !important;
          }
          @media (max-width: map-get($mediaSizes, 'maxxl')) {
            min-height: 470px;
          }
        }
        &.novalid {
          border-color: $pink;
          .card-body {
            padding: 37px 25px 33px 25px !important;
            @media (max-width: map-get($mediaSizes, 'lg')) {
              padding: 1rem !important;
            }
            @media all and (max-width: 815px) {
              padding: 37px 25px 33px 25px !important;
            }
            p {
              color: $pink;
            }
          }
        }
        &.novalid-grey {
          border-color: $grey;
          .card-body {
            padding: 37px 25px 33px 25px !important;
            @media (max-width: map-get($mediaSizes, 'lg')) {
              padding: 1rem !important;
            }
            @media all and (max-width: 815px) {
              padding: 37px 25px 33px 25px !important;
            }
            p {
              color: $grey;
            }
          }
        }
        ƒ &.light-card {
          background-color: $light_blue1;
          .card-body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .one {
              padding-right: 16px;
            }
            span {
              color: $light_blue !important;
            }
            p {
              color: $primary !important;
            }
          }
        }
        .card-body {
          padding: 23px;
          @media (max-width: map-get($mediaSizes, 'lg')) {
            padding: 1rem;
          }
          @media all and (max-width: 815px) {
            padding: 37px 25px 33px 25px !important;
          }
          .span-10 {
            margin-bottom: 8px;
            text-transform: uppercase;
            color: $white;
          }
          p {
            @include span_16();
            color: $white;
            margin-bottom: 0px;
            &.scroll {
              overflow-y: auto;
              overflow-x: hidden;
              width: calc(100% + 10px);
              padding-right: 10px;
              @media (max-width: map-get($mediaSizes, 'xxl')) {
                max-height: 300px !important;
              }
              @media (max-width: map-get($mediaSizes, 'maxxl')) {
                max-height: 350px;
              }
              &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: $body_color;
              }

              &::-webkit-scrollbar {
                width: 0.375rem;
                background-color: $body_color;
                border-radius: 10px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: rgba($placeholder_color, 1);
              }
            }
          }
          a.text {
            @include span_14();
            font-weight: 600 !important;
            color: $white !important;
            margin-bottom: 0px;
            transition: 0.3s ease-in-out;
            &:hover {
              color: $red_hover !important;
            }
          }
          .img-data {
            text-align: center;
            @media (max-width: map-get($mediaSizes, 'xxl')) {
              padding-top: 36px !important;
              padding-bottom: 30px !important;
            }
            @media (max-width: map-get($mediaSizes, 'maxxl')) {
              padding-top: 85px;
              padding-bottom: 86px;
            }
          }
        }
      }
    }
  }
}

// .Toastify
.Toastify__toast-container {
  padding: 0 !important;
  top: 38px;
  right: 20px;
  width: fit-content !important;
  max-width: 800px;
}
.Toastify__toast-theme--light {
  border-radius: 4px !important;
  box-shadow: none !important;
  &.Toastify__toast--success {
    background: $light_success;
    align-items: center;
    padding: 15px;
    .Toastify__toast-icon {
      img {
        filter: brightness(0) invert(1);
      }
    }
    .Toastify__toast-body {
      div {
        color: $white;
      }
    }
    .Toastify__close-button--light {
      color: $white;
    }
  }
  &.Toastify__toast--error {
    background: $toast_background;
    align-items: center;
    padding: 15px;
    .Toastify__toast-icon {
      img {
        filter: brightness(0) invert(1);
      }
    }
    .Toastify__toast-body {
      div {
        color: $white;
      }
    }
    .Toastify__close-button--light {
      color: $white;
    }
  }
  .Toastify__close-button--light {
    opacity: 1;
    color: $primary;
  }
  .Toastify__toast-body {
    padding: 0;
    padding-right: 15px;
    .Toastify__toast-icon {
      width: 17px;
      height: 17px;
      margin-right: 11px;
    }
    div {
      color: $primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .Toastify__close-button {
    align-self: unset;
  }
}

// common-modal
.common-modal {
  @media (min-width: map-get($mediaSizes, 'xs')) {
    &.modal-dialog {
      width: 380px;
      max-width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  .modal-content {
    box-shadow: 0px 3px 16px 0px rgba($black, 0.16);
    border: 0;
    border-radius: 8px;
  }
  .modal-body {
    position: relative;
    padding: 25px 25px 30px 25px;
    .close-modal {
      position: absolute;
      top: 29px;
      right: 29px;
    }
    .icon-modal {
      margin-bottom: 32px;
      .icon-warning {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $light_warning;
        margin: 0 auto;
        @include all_center();
      }
    }
    h3.modal-title {
      margin-bottom: 16px;
    }
    p.modal-text {
      margin-bottom: 18px;
    }
    p.mb-32 {
      margin-bottom: 32px;
    }
    .react-12-cut {
      margin: 0 auto;
    }
    .modal-big-btn {
      margin: 0 auto;
      border-radius: 12px 12px 0px 12px !important;
      @include all_center();
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600 !important;
    }
  }
}
.right-main-logo {
  height: 50px;
  width: 50px;
}

// Ripple color btn css
// #e6edf9
.color-pink {
  s {
    background-color: $pink !important;
  }
}

// #f7f8fc
.color-off-white {
  s {
    background-color: $body_color !important;
  }
}
